import Link from "next/link";
import Image from "next/image";
import ChevronDown from "@/public/icons/chevron-down.svg";
import {
  SecondaryNavigationNode,
  TertiaryNavigationNode,
} from "@/services/prepr/queries/getLayoutContent";
import { getUrlFromNavigationNode } from "@/utilities/getUrlFromNavigationNode";
import { useDialogForms } from "../../useDialogForms";
import SubMenu from "./SubMenu";
import useStateAndDelayNull from "./useStateAndDelayNull";

const MenuLink: React.FC<{
  node: SecondaryNavigationNode | TertiaryNavigationNode;
  setHoverTarget: (hoverTarget: string | null) => void;
}> = ({ node, setHoverTarget }) => {
  const onHover = () => {
    setHoverTarget("nothing");
  };

  return (
    <div className="bg-secondary-aubergine relative" onMouseEnter={onHover}>
      <Link href={getUrlFromNavigationNode(node)}>
        <a
          className="text-menu-sm block p-6 font-bold hover:bg-white/20"
          data-gtm-id="second_navigation"
        >
          {node.title}
        </a>
      </Link>
    </div>
  );
};

const MenuItemWithSubMenu: React.FC<{
  node: SecondaryNavigationNode;
  show: boolean;
  hoverTarget: string | null;
  setHoverTarget: (hoverTarget: string | null) => void;
  resetDelay: () => void;
}> = ({ node, show, hoverTarget, setHoverTarget, resetDelay }) => {
  const resetHoverTarget = () => {
    setHoverTarget(null);
  };
  const onHover = () => {
    setHoverTarget(node.title ?? "");
  };

  return (
    <div onMouseLeave={resetHoverTarget} onMouseEnter={resetDelay}>
      <SubMenu nodes={node.nodes || []} show={show && hoverTarget === (node.title ?? "")} />
      <div className="bg-secondary-aubergine relative">
        <Link href={getUrlFromNavigationNode(node)} prefetch={false}>
          <a
            className="text-menu-sm group block cursor-pointer select-none p-6 font-bold hover:bg-white/20"
            onMouseEnter={onHover}
            data-gtm-id="second_navigation"
          >
            <span className="mr-3">{node.title}</span>
            <Image
              src={ChevronDown}
              alt="chevron down"
              width="16"
              className={hoverTarget === (node.title ?? "") ? "rotate-180 transition-all" : ""}
            />
          </a>
        </Link>
      </div>
    </div>
  );
};

const Menu: React.FC<{
  nodes: SecondaryNavigationNode[];
  show: boolean;
  adviesGesprekButton?: boolean;
}> = ({ nodes, show, adviesGesprekButton = true }) => {
  const [hoverTarget, setHoverTarget, resetDelay] = useStateAndDelayNull<string | null>(null, 1500);
  const { openDialog } = useDialogForms();
  return (
    <>
      <div
        data-testid="secondary-node"
        className={`bg-secondary-aubergine absolute top-full w-full transition-all ${
          show ? "visible translate-y-0" : "invisible -translate-y-full"
        }`}
      >
        <div className="max-w-screen-desktop-xl relative mx-auto flex w-full items-center justify-center">
          <div className="flex w-full justify-start">
            {nodes.map((item) =>
              !!item.nodes?.length ? (
                <MenuItemWithSubMenu
                  node={item}
                  key={item.title}
                  show={show}
                  hoverTarget={hoverTarget}
                  setHoverTarget={setHoverTarget}
                  resetDelay={resetDelay}
                />
              ) : (
                <MenuLink node={item} key={item.title} setHoverTarget={setHoverTarget} />
              )
            )}
            <div className="bg-secondary-aubergine relative flex flex-1" />
          </div>
          {adviesGesprekButton && (
            <button
              className="bg-primary-black text-menu-sm flex h-12 items-center justify-center whitespace-nowrap rounded-xl p-4 font-bold"
              onClick={openDialog("lead-requestAppointment", "Maak een afspraak (lead)")}
            >
              Gratis adviesgesprek
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Menu;
