import React from "react";
import Link from "next/link";
import Image from "next/image";
import Button from "@/components/shared/Button";
import FormInput from "@/components/shared/Forms/FormInput";
import { useFormState } from "@/components/shared/DialogForms/useFormState";
import type { RegisterForNewsletterForm } from "@/services/salesforce/models/RegisterForNewsletterForm";
import CheckMark from "@/components/Icons/CheckMark";
import { emailIsValid } from "@/utilities/emailIsValid";
import { useFormErrorDataLayerPush } from "@/components/shared/GTM/useFormErrorDataLayerPush";
import { useDataLayerEvent } from "@/hooks/useDatalayerEvent";
import { FORM_SOURCE_TYPE } from "@/services/salesforce/models/FormSource";
import { LEAD_SOURCE } from "@/services/salesforce/models/LeadSource";

const initialModel: RegisterForNewsletterForm = {
  email: "",
  formSource: FORM_SOURCE_TYPE.RegisterForNewsletter,
  leadSource: LEAD_SOURCE.webform,
};

const initialValidation = {
  email: null,
  formSource: null,
  leadSource: null,
};

const getValidationMessage =
  (model: RegisterForNewsletterForm) =>
  (field: keyof RegisterForNewsletterForm): string | null => {
    const value = model[field];
    if (field === "email") {
      if (!value) return "E-mail is vereist";
      if (!emailIsValid(value)) return "E-mail is ongeldig";
    }
    return null;
  };

const NewsletterForm: React.FC = () => {
  const { isSuccess, isError, isLoading, handler, model, validation, validateAndSubmit } =
    useFormState<RegisterForNewsletterForm>({
      initialModel,
      initialValidation,
      getValidationMessage,
      entity: "lead",
    });
  useDataLayerEvent({
    blocked: !isSuccess,
    events: [{ event: "formSuccess", formName: "nieuwsbrief aanmelden" }],
  });
  useFormErrorDataLayerPush("nieuwsbrief aanmelden", validation);
  return (
    <div className="tablet:mt-16 tablet:px-12 tablet:py-10 mx-4 bg-white px-6 py-8 text-black">
      <p className="text-forms text-secondary-burgundy tablet:text-h4-sm mb-4 font-bold tracking-wider">
        Meld je aan voor de nieuwsbrief
      </p>
      <p className="text-p mb-6 font-serif tracking-wider">
        Op de hoogte blijven van het laatste nieuws rondom Hiltermann?
      </p>
      <FormInput
        testId="email"
        value={model.email}
        onChange={handler.onFieldChange("email")}
        onBlur={handler.onBlur("email")}
        placeholder="E-mailadres"
        disabled={isLoading}
        error={
          isError ? (
            <div className="flex flex-row gap-2">
              <Image src="/icons/error.svg" width="24" height="24" alt="error icon" />
              <div className="mt-0.5">Aanmelding mislukt. Probeer het later nog eens.</div>
            </div>
          ) : (
            validation.email
          )
        }
        success={
          isSuccess && (
            <div className="flex flex-row gap-2">
              <CheckMark className="h-6 w-6" />
              <div className="mt-0.5">Je bent succesvol aangemeld!</div>
            </div>
          )
        }
        inputClass="tablet:text-forms tablet:py-5"
        className="tablet:mb-6 mb-4 max-w-[420px] "
      />
      <Button
        variant="secondary"
        className="mb-6 w-full"
        onClick={validateAndSubmit}
        disabled={isLoading}
      >
        Aanmelden
      </Button>
      <p className="text-primary-airforce mb-9 text-sm">
        We gaan zorgvuldig met je gegevens om, zie{" "}
        <Link href={"https://www.hiltermannlease.nl/privacyverklaring"}>
          <a className="underline">privacyverklaring.</a>
        </Link>
      </p>
      <div className="flex">
        <Link href="https://www.facebook.com/hiltermannlease/">
          <a
            className="bg-grey-light hover:bg-grey-dolphin mr-4 flex h-10 w-10 items-center justify-center rounded-full"
            target="_blank"
          >
            <Image src="/icons/facebook.svg" alt="facebook logo" width="9" height="18" />
          </a>
        </Link>
        <Link href="https://www.instagram.com/hiltermannlease/">
          <a
            className="bg-grey-light hover:bg-grey-dolphin mr-4 flex h-10 w-10 items-center justify-center rounded-full"
            target="_blank"
          >
            <Image src="/icons/instagram.svg" alt="instagram logo" width="18" height="18" />
          </a>
        </Link>
        <Link href="https://nl.linkedin.com/company/hiltermannlease-">
          <a
            className="bg-grey-light hover:bg-grey-dolphin flex h-10 w-10 items-center justify-center rounded-full"
            target="_blank"
          >
            <Image src="/icons/linkedin.svg" alt="linkedin logo" width="18" height="18" />
          </a>
        </Link>
      </div>
    </div>
  );
};

export default NewsletterForm;
