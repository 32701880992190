import clsx from "clsx";
import { ChangeEvent, RefObject } from "react";
import { RefCallBack } from "react-hook-form";

interface Props {
  name?: string;
  label: string;
  value: string;
  onChange: (v: string) => void;
  onBlur?: (v: string) => void;
  error?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  inputRef?: RefObject<HTMLTextAreaElement> | RefCallBack;
}

const FormTextarea: React.FC<Props> = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  name,
  inputRef,
  disabled = false,
  placeholder = undefined,
  className,
}) => {
  const handleChange: (event: ChangeEvent<{ value: string }>) => void = (event) =>
    onChange(event.target.value);
  const handleBlur: (event: ChangeEvent<{ value: string }>) => void = (event) =>
    onBlur ? onBlur(event.target.value) : null;
  const errorClass = error
    ? "border-secondary-aubergine text-secondary-aubergine"
    : "border-grey-asphalt text-primary-black";
  return (
    <div className={clsx("tablet:mb-6 relative mb-4", className)}>
      <label
        htmlFor={label}
        className="text-label-xs block pb-2 font-sans font-bold tracking-wider"
      >
        {label}
      </label>
      <textarea
        name={name}
        ref={inputRef}
        data-testid={`textarea-${label}`}
        id={label}
        className={clsx(
          "text-label-xs w-full rounded-lg border px-6 py-3 font-bold",
          !disabled && "hover:m-[-1px] hover:border-2",
          "focus:outline-secondary-aubergine focus:m-[-1px] focus:border-2",
          errorClass
        )}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        rows={6}
      />
      {error && (
        <div className="bg-secondary-pink text-p-sm text-secondary-aubergine -z-10 -mt-2 w-full rounded-b-lg px-6 pb-4 pt-5 font-bold">
          {error}
        </div>
      )}
    </div>
  );
};

export default FormTextarea;
