export enum FORM_SOURCE_TYPE {
  // leads
  RegisterForNewsletter = "Inschrijven nieuwsbrief",
  ActionCarRequest = "Actie auto aanvraag",
  OccasionRequest = "Occasion aanvraag",
  ShortleaseRequest = "Shortlease aanvraag",
  MakeAppointment = "Afspraak inplannen",
  SpecificAppointment = "Afspraak inplannen met",
  InventoryFinancingContact = "Voorraadfinanciering",
  Mobiliteitsscan = "Mobiliteitsscan",
  // cases
  ContactForm = "Contactformulier",
  CallMeBack = "Bel me terug verzoek",
  Recruitment = "Recruitment afspraak",
  EqlAanvraag = "EQL aanvraag",
  // whitepapers
  Whitepaper = "Whitepaper aanvragen",
  // events
  RecruitmentAppointment = "Recruitment afspraak WK",
}
