import Image from "next/image";
import { useRouter } from "next/router";
import Button from "@/components/shared/Button";
import Dialog from "@/components/shared/DialogForms/Dialog";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  closeButtonText?: string | undefined;
  className?: string;
  headerText?: string | undefined;
  bodyText?: string | undefined;
  image?: string | undefined | null;
  imageDescription?: string | null;
  downloadUrl?: string;
  downloadTitle?: string;
}

const DialogMessage: React.FC<Props> = ({
  isOpen,
  onClose,
  closeButtonText,
  className,
  headerText,
  bodyText,
  image,
  imageDescription,
  downloadUrl,
  downloadTitle,
}) => {
  const router = useRouter();
  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={className}>
      <div className="tablet:p-14 flex flex-col px-6 py-10">
        <h2 className="text-h3-sm tablet:text-h2-sm mb-4 w-full text-center font-bold tracking-wider">
          {headerText || "Bedankt voor je aanvraag!"}
        </h2>
        <div className="text-p-sm tablet:text-p mb-8 text-center font-serif tracking-wider">
          {bodyText ||
            "Je vindt een bevestiging in je inbox. Wij gaan met je aanvraag aan de slag en komen zo snel mogelijk bij je terug."}
        </div>
        {!!image && (
          <div className="flex justify-center">
            <div className="relative h-48 w-48">
              <Image
                src={image}
                layout="fill"
                className="bg-grey-light rounded-full"
                alt={imageDescription || "employee image"}
              />
            </div>
          </div>
        )}
        {downloadUrl && (
          <Button
            size="small"
            variant="secondary"
            onClick={() => router.push(downloadUrl)}
            className="tablet:self-center mt-6"
          >
            {downloadTitle ? `Download ${downloadTitle}` : "Download"}
          </Button>
        )}
        <Button
          onClick={onClose}
          variant="tertiary"
          size="small"
          className="tablet:self-center mt-6"
        >
          {closeButtonText || "Venster sluiten"}
        </Button>
      </div>
    </Dialog>
  );
};

export default DialogMessage;
