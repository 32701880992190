import { MouseEventHandler } from "react";
import Link from "next/link";

interface Props {
  href: string;
  title: string;
  onMouseEnter: MouseEventHandler<HTMLAnchorElement> | undefined;
}

const NavbarSecondaryLink: React.FC<Props> = ({ href, title, onMouseEnter }: Props) => (
  <Link href={href}>
    <a
      className="group flex items-center px-6"
      onMouseEnter={onMouseEnter}
      data-gtm-id="main_navigation"
    >
      <div className="text-menu-sm group-hover:before:from-secondary-aubergine border-transparent py-4 font-bold group-hover:relative group-hover:before:absolute group-hover:before:bottom-0 group-hover:before:h-[2px] group-hover:before:w-full group-hover:before:bg-gradient-to-r group-hover:before:to-[rgba(0,0,0,0)] group-hover:before:content-['']">
        {title}
      </div>
    </a>
  </Link>
);

export default NavbarSecondaryLink;
