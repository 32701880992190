import Link from "next/link";

interface FooterLinkProps {
  href: string;
  children: JSX.Element | string;
  className?: string;
}

const FooterLink = ({ href, children, className }: FooterLinkProps) => (
  <li className={className}>
    <Link href={href}>
      <a className="hover:underline" data-gtm-id="footer_navigation">
        {children}
      </a>
    </Link>
  </li>
);

export default FooterLink;
