import { fromPairs, keys } from "ramda";

export type ValidationModel<T> = { [A in keyof T]: string | null };

type GetValidationMessage<T> = (model: T) => (field: keyof T) => string | null;

export const validateModel =
  <K extends keyof T, T extends { [X in K]: string }>(
    getValidationMessage: GetValidationMessage<T>
  ) =>
  (model: T): ValidationModel<T> => {
    type Field = string;
    type ValidationMessage = string | null;
    const modelKeys = keys(model);
    const newPairs: Readonly<[Field, ValidationMessage][]> = modelKeys.map((field) => [
      field as string,
      getValidationMessage(model)(field),
    ]);
    return fromPairs(newPairs) as ValidationModel<T>;
  };
