import Link from "next/link";
import ContactFormInput from "@/components/shared//DialogForms/ContactFormInput";
import FormTextarea from "@/components/shared/Forms/FormTextarea";
import { ContactForm } from "@/services/salesforce/models/ContactForm";
import DialogFormWrapper from "@/components/shared/DialogForms/DialogFormWrapper";
import { SalesforceEntity } from "@/services/salesforce/models/SalesForceEntity";
import { ActiveDialog } from "@/components/Layout/useDialogForms";
import { useFormErrorDataLayerPush } from "@/components/shared/GTM/useFormErrorDataLayerPush";
import { parseText } from "@/utilities/htmlParser";
import { useDataLayerEvent } from "@/hooks/useDatalayerEvent";
import { FORM_SOURCE_TYPE } from "@/services/salesforce/models/FormSource";
import { useFormState } from "../../../shared/DialogForms/useFormState";
import { getValidationMessage } from "./DialogContact/validation";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (model: ContactForm) => void;
  onSubmitted?: () => void;
  entity?: SalesforceEntity;
  setActiveDialog?: React.Dispatch<React.SetStateAction<ActiveDialog>>;
  title?: string | null | undefined;
  description?: string | null | undefined;
  messagePlaceholder?: string;
}

const initialModel: ContactForm = {
  firstName: "",
  lastName: "",
  companyName: "",
  phone: "",
  email: "",
  subject: "",
  message: "",
  formSource: FORM_SOURCE_TYPE.ContactForm,
};

const initialValidation = {
  firstName: null,
  lastName: null,
  companyName: null,
  phone: null,
  email: null,
  subject: null,
  message: null,
  formSource: null,
};

const DialogContact: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmitted,
  entity = "case",
  setActiveDialog,
  title,
  description,
  messagePlaceholder,
}) => {
  const fallbackDescription = (
    <span>
      Staat het antwoord op je vraag niet in{" "}
      <Link href={"/service-contact"}>
        <a
          className="text-secondary-burgundy font-bold"
          onClick={() => setActiveDialog && setActiveDialog(null)}
        >
          onze FAQ
        </a>
      </Link>
      ? Vul dan het onderstaande contactformulier in. Wij zorgen ervoor dat één van onze medewerkers
      zo snel mogelijk contact met je opneemt.
    </span>
  );
  const parsedDescription = parseText(description || "");
  const fallbackTitle = "Neem contact met ons op";

  const {
    isError,
    handler,
    model,
    validation,
    handleClose,
    validateAndSubmit,
    isLoading,
    isSuccess,
    hasValidationError,
  } = useFormState({
    initialModel,
    initialValidation,
    onSubmitted,
    onClose,
    getValidationMessage,
    entity: entity,
  });
  useDataLayerEvent({
    blocked: !isSuccess,
    events: [{ event: "formSuccess", formName: "contactformulier" }],
  });
  useFormErrorDataLayerPush("ContactFormulier", validation);
  const inputProps = { model, eventHandler: handler, validation, disabled: isLoading };
  return (
    <DialogFormWrapper
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={validateAndSubmit}
      title={title || fallbackTitle}
      description={description ? parsedDescription : fallbackDescription}
      submitText="Verzend je bericht"
      error={isError}
      hasValidationError={hasValidationError}
      isLoading={isLoading}
    >
      <>
        <div className="tablet:grid-cols-2 grid grid-cols-1 gap-x-6">
          <ContactFormInput label="Voornaam*" field="firstName" {...inputProps} />
          <ContactFormInput label="Achternaam*" field="lastName" {...inputProps} />
          <ContactFormInput label="Bedrijfsnaam" field="companyName" {...inputProps} />
          <ContactFormInput label="Telefoonnummer*" field="phone" type="phone" {...inputProps} />
          <ContactFormInput label="E-mailadres*" field="email" type="email" {...inputProps} />
          <ContactFormInput label="Betreft*" field="subject" {...inputProps} />
        </div>
        <FormTextarea
          label="Bericht"
          value={model.message}
          onChange={handler.onFieldChange("message")}
          placeholder={messagePlaceholder}
          disabled={isLoading}
        />
      </>
    </DialogFormWrapper>
  );
};

export default DialogContact;
