import Link from "next/link";
import Image from "next/image";
import LogoFull from "@/public/icons/logo-full.svg";
import LogoNVL from "@/public/icons/logo-nvl.svg";
import LogoVNA from "@/public/icons/logo-vna.svg";
import { Footer as FooterPrerpType } from "@/services/prepr/queries/getLayoutContent";
import NewsletterForm from "./Footer/NewsletterForm";
import FooterLinks from "./Footer/FooterLinks";
import CopyrightFooter from "./Footer/CopyrightFooter";

interface Props {
  footer: FooterPrerpType;
}

const Footer: React.FC<Props> = ({ footer }) => {
  return (
    <footer>
      <div className="bg-primary-black tablet:px-0 flex justify-center px-4 text-white">
        <div className="max-w-screen-desktop-xl tablet:flex tablet:px-8 w-full">
          <div className="tablet:w-2/3 tablet:pr-36">
            <div className="tablet:flex tablet:border-b tablet:border-grey-asphalt mb-10 justify-between">
              <div className="border-grey-asphalt tablet:border-0 flex border-b py-12">
                <Link href="/">
                  <a className="mr-10">
                    <Image src={LogoFull} alt="logo Hiltermann Lease" height="60" />
                  </a>
                </Link>
              </div>
              <div className="border-grey-asphalt tablet:border-0 flex items-center border-b py-10">
                <span className="text-p-sm tablet:text-p mr-6 font-serif tracking-wider">
                  Aangesloten bij
                </span>
                <div className="mr-6">
                  <Link href="https://nvl-lease.nl/">
                    <a target="_blank">
                      <Image src={LogoNVL} alt="logo NVL" height="48" width="48" />
                    </a>
                  </Link>
                </div>
                <Link href="https://www.vna-lease.nl/">
                  <a target="_blank">
                    <Image src={LogoVNA} alt="logo VNA" height="48" width="48" />
                  </a>
                </Link>
              </div>
            </div>
            <FooterLinks footer={footer} />
          </div>
          <NewsletterForm />
        </div>
      </div>

      <CopyrightFooter
        links={[
          {
            href: "/privacyverklaring",
            label: "Privacyverklaring",
          },
          {
            href: "/cookieverklaring",
            label: "Cookieverklaring",
          },
          {
            href: "/disclaimer",
            label: "Disclaimer",
          },
        ]}
      />
    </footer>
  );
};

export default Footer;
