import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Hamburger from "@/public/icons/hamburger.svg";
import Close from "@/public/icons/close.svg";
import Logo from "@/public/icons/logo.svg";
import User from "@/public/icons/user.svg";
import Search from "@/public/icons/search.svg";
import SearchInput from "@/components/Layout/Navbar/SearchInput";
import Button from "@/components/shared/Button";
import { NavbarProps } from "../Navbar";
import { HoverTarget } from "./NavbarDesktop";
import { extractMenuItems } from "./extractMenuItems";
import Menu from "./NavbarMobile/Menu";
import MainMenu from "./NavbarMobile/MainMenu";
import AccountMenu from "./NavbarMobile/AccountMenu";

export type SubMenu = HoverTarget;

type SearchWithButtonProps = {
  buttonClassName: string;
  inputClassName: string;
  onAfterSubmit?: () => void;
};

const SearchInputWithButton = ({
  buttonClassName,
  inputClassName,
  onAfterSubmit,
}: SearchWithButtonProps) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>(undefined);
  const router = useRouter();
  const onSubmit = () => {
    if (currentValue !== "" && currentValue) router.push("/search?q=" + currentValue);
    onAfterSubmit && onAfterSubmit();
  };

  return (
    <>
      <SearchInput
        className={inputClassName}
        currentValueCallback={setCurrentValue}
        onAfterSubmit={onAfterSubmit}
      />
      <Button variant={"secondary"} className={buttonClassName} onClick={onSubmit}>
        Zoeken
      </Button>
    </>
  );
};

const NavbarMobile: React.FC<NavbarProps> = ({ navigation, type = "default" }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState<SubMenu | string>("nothing");
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const {
    menuFietsAanbod,
    menuFietsHoeWerktHet,
    menuFietsVindDealer,
    menuAutoLease,
    menuFinancieren,
    menuAboutUs,
    menuService,
  } = extractMenuItems(navigation);
  const toggleAccountMenu = () => setShowAccountMenu(!showAccountMenu);
  const toggleSearch = () => setShowSearch((value) => !value);
  const closeAccountMenu = () => setShowAccountMenu(false);
  const closeMenu = () => {
    setShowMobileMenu(false);
    setActiveSubMenu("nothing");
  };
  const clickMenu = () => {
    if (showMobileMenu && showAccountMenu) closeAccountMenu();
    showMobileMenu ? closeMenu() : setShowMobileMenu(true);
  };
  useEffect(() => {
    document.body.style.overflow = showMobileMenu ? "hidden" : "auto";
  }, [showMobileMenu]);
  useEffect(() => {
    if (showSearch) setShowMobileMenu(false);
  }, [showSearch]);

  return (
    <>
      {/* Mobile navbar */}
      <div className="tablet:hidden h-16">&nbsp;</div>
      <nav
        id="mobile-navbar"
        className="bg-transparant tablet:hidden fixed inset-x-0 top-0 z-20 text-white"
      >
        <div className="bg-primary-black flex justify-center">
          <div className="max-w-screen-desktop-xl flex w-full justify-between">
            <button className="z-20 flex h-full w-1/3 items-center p-4" onClick={clickMenu}>
              <Image
                src={showMobileMenu ? Close : Hamburger}
                alt="Open of sluit het menu"
                width="32"
                height="32"
              ></Image>
              <div className="flex h-full items-center pl-4">
                {showMobileMenu ? "Sluit" : "Menu"}
              </div>
            </button>

            <div className="flex">
              <Link href="/">
                <a className="flex p-3">
                  <Image
                    src={Logo}
                    alt="Menu icon"
                    width="40"
                    height="40"
                    data-gtm-id="logo_navigation"
                  ></Image>
                </a>
              </Link>
            </div>

            <div className="flex w-1/3 justify-end">
              <button
                type="button"
                className="mr-4 flex h-16 w-16 items-center justify-center rounded-2xl"
                onClick={() => toggleSearch()}
                data-gtm-id="search_icon"
              >
                <Image
                  src={showSearch ? Close : Search}
                  alt="Search icon"
                  width="32"
                  height="32"
                ></Image>
              </button>

              <button
                className="flex h-16 w-16 items-center justify-center"
                data-gtm-id="login_icon"
                onClick={toggleAccountMenu}
              >
                <Image src={User} alt="User icon" width="32" height="32"></Image>
              </button>
            </div>
          </div>
        </div>

        <div
          data-testid="mobile-menu"
          className={`fixed inset-x-0 bottom-0 top-16 h-full transition-all ${
            showMobileMenu ? "visible translate-x-0" : "invisible -translate-x-full"
          }`}
        >
          <MainMenu
            type={type}
            closeMenu={closeMenu}
            setSubMenu={setActiveSubMenu}
            aboutHasMenu={!!menuAboutUs?.hasMenu}
            serviceHasMenu={!!menuService?.hasMenu}
          />

          {activeSubMenu !== "nothing" && (
            <div className="bg-secondary-aubergine absolute inset-x-0 top-0 z-20 h-full">
              {type === "default" ? (
                <>
                  {activeSubMenu === "autolease" && (
                    <Menu
                      href={menuAutoLease?.target ?? "/autolease"}
                      menuItems={menuAutoLease?.menuItems ?? []}
                      menuTitle="Autolease"
                      setActiveSubMenu={setActiveSubMenu}
                      closeMenu={closeMenu}
                    />
                  )}
                  {activeSubMenu === "financieren" && (
                    <Menu
                      href={menuFinancieren?.target ?? "/financieren"}
                      menuItems={menuFinancieren?.menuItems ?? []}
                      menuTitle="Financieren"
                      setActiveSubMenu={setActiveSubMenu}
                      closeMenu={closeMenu}
                    />
                  )}
                </>
              ) : (
                <>
                  {activeSubMenu === "fiets-aanbod" && (
                    <Menu
                      href={menuFietsAanbod?.target ?? "/fiets/aanbod"}
                      menuItems={menuFietsAanbod?.menuItems ?? []}
                      menuTitle="Fiets aanbod"
                      setActiveSubMenu={setActiveSubMenu}
                      closeMenu={closeMenu}
                      adviesGesprekButton={false}
                    />
                  )}
                  {activeSubMenu === "calculator" && (
                    <Menu
                      href={menuFietsHoeWerktHet?.target ?? "/fiets/calculator"}
                      menuItems={menuFietsHoeWerktHet?.menuItems ?? []}
                      menuTitle="Calculator?"
                      setActiveSubMenu={setActiveSubMenu}
                      closeMenu={closeMenu}
                      adviesGesprekButton={false}
                    />
                  )}
                  {activeSubMenu === "vind-dealer" && (
                    <Menu
                      href={menuFietsVindDealer?.target ?? "/fiets/vind-een-dealer"}
                      menuItems={menuFietsVindDealer?.menuItems ?? []}
                      menuTitle="Vind een dealer"
                      setActiveSubMenu={setActiveSubMenu}
                      closeMenu={closeMenu}
                      adviesGesprekButton={false}
                    />
                  )}
                </>
              )}
              {menuService?.hasMenu && activeSubMenu === "service" && (
                <Menu
                  href={menuService?.target ?? "/service"}
                  menuItems={menuService?.menuItems}
                  menuTitle="Service & Contact"
                  setActiveSubMenu={setActiveSubMenu}
                  closeMenu={closeMenu}
                />
              )}
              {/* aboutHasMenu is fallback functionality */}
              {type === "default" && menuAboutUs?.hasMenu && activeSubMenu === "over-ons" && (
                <Menu
                  href={menuAboutUs?.target ?? "/over-ons"}
                  menuItems={menuAboutUs?.menuItems}
                  menuTitle="Over Hiltermann Lease"
                  setActiveSubMenu={setActiveSubMenu}
                  closeMenu={closeMenu}
                />
              )}
            </div>
          )}
        </div>

        <div
          data-testid="mobile-search"
          className={`fixed inset-x-0 bottom-0 top-16 h-full transition-all ${
            showSearch ? "visible translate-x-0" : "invisible -translate-x-full"
          }`}
        >
          <div
            data-testid="mobile-search"
            className="bg-primary-black relative h-full flex-col px-4"
          >
            <SearchInputWithButton
              buttonClassName="mt-4 w-full"
              inputClassName="pt-4"
              onAfterSubmit={() => setShowSearch(false)}
            />
          </div>
        </div>

        <div
          className={`fixed inset-x-0 bottom-0 top-16 h-full transition-transform ${
            showAccountMenu ? "visible translate-x-0" : "invisible translate-x-full"
          }`}
        >
          <AccountMenu closeMenu={closeAccountMenu} />
        </div>
      </nav>
    </>
  );
};

export default NavbarMobile;
