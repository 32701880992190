import { NavbarProps } from "@/components/Layout/Navbar";
import { SecondaryNavigationNode } from "@/services/prepr/queries/getLayoutContent";
const navbarItems = [
  "Autolease",
  "Financieren",
  "Over Hiltermann Lease",
  "Service & Contact",
  "Home",
  "Fiets aanbod",
  "Calculator",
  "Vind een dealer",
];

interface Menu {
  menuItemName: string;
  menuItems: SecondaryNavigationNode[];
  target: string | null | undefined;
  hasMenu: boolean;
}

export function extractMenuItems(navigation: NavbarProps["navigation"]) {
  const items = navbarItems.map((navbarItem): Menu => {
    const menuItems = navigation?.find((n) => n.navbar_item === navbarItem)?.nodes || [];
    return {
      menuItemName: navbarItem,
      menuItems: menuItems as SecondaryNavigationNode[],
      target: navigation?.find((n) => n.navbar_item == navbarItem)?.target[0]._slug,
      hasMenu: !!menuItems.length,
    };
  });
  const menuAutoLease = items.find((item) => item.menuItemName == "Autolease");
  const menuFinancieren = items.find((item) => item.menuItemName == "Financieren");
  const menuService = items.find((item) => item.menuItemName == "Service & Contact");
  // const menuAboutUs = items.find((item) => item.menuItemName == "Over ons");

  // fallback for navbar item name change from 'Over Hiltermann Lease' to 'Over ons', when changing in Prepr it should also be added to the navbarItems array.
  const menuAboutHiltermann = items.find((item) => item.menuItemName == "Over Hiltermann Lease");
  const menuAbout = items.find((item) => item.menuItemName == "Over ons");
  const menuAboutUs =
    navigation?.find((n) => n.navbar_item === "Over ons") != undefined
      ? menuAbout
      : menuAboutHiltermann;

  // /fiets menu
  const menuFietsHome = items.find((item) => item.menuItemName == "Home");
  const menuFietsAanbod = items.find((item) => item.menuItemName == "Fiets aanbod");
  const menuFietsHoeWerktHet = items.find((item) => item.menuItemName == "Calculator");
  const menuFietsVindDealer = items.find((item) => item.menuItemName == "Vind een dealer");

  return {
    menuAutoLease,
    menuFinancieren,
    menuAboutUs,
    menuService,
    menuFietsHome,
    menuFietsAanbod,
    menuFietsHoeWerktHet,
    menuFietsVindDealer,
  };
}
