import clsx from "clsx";
import Link from "next/link";
import ButtonContent from "./Button/ButtonContent";

type ButtonVariant = "primary" | "secondary" | "tertiary";

interface Props {
  type?: "button" | "link" | "externalLink" | "div";
  variant?: ButtonVariant;
  className?: string;
  children: JSX.Element | string;
  icon?: React.ReactElement;
  iconPosition?: "start" | "end" | "center";
  href?: string | undefined | null;
  onClick?: () => void;
  size?: "normal" | "small";
  "aria-label"?: string;
  disabled?: boolean;
  testId?: string;
}

const Button: React.FC<Props> = ({
  type = "button",
  variant = "primary",
  className,
  children,
  icon,
  iconPosition = "end",
  href,
  onClick,
  size = "normal",
  "aria-label": ariaLabel,
  disabled = false,
  testId,
}) => {
  const classes = clsx(
    "border-gradient-base transition-1 shadow-button inline-flex max-w-[420px] flex-row items-center justify-center text-center font-sans font-semibold outline-2 before:bg-gradient-to-r",
    "focus-visible:outline",
    disabled && "opacity-50",
    sizeStyles[size],
    variantStyles[variant],
    !disabled && variantHoverStyles[variant],
    className
  );

  if (type === "button") {
    return (
      <button
        type="button"
        className={classes}
        onClick={onClick}
        aria-label={ariaLabel}
        disabled={disabled}
        data-gtm-id={`btn-${variant}`}
        data-testid={testId}
      >
        <ButtonContent icon={icon} iconPosition={iconPosition}>
          {children}
        </ButtonContent>
      </button>
    );
  }
  if (type === "link") {
    return (
      <Link href={href || "/404"}>
        <a className={classes} data-testid={testId} data-gtm-id={`btn-${variant}`}>
          <ButtonContent icon={icon} iconPosition={iconPosition}>
            {children}
          </ButtonContent>
        </a>
      </Link>
    );
  }
  if (type === "externalLink" && href) {
    return (
      <a
        className={classes}
        href={href.startsWith("http") ? href : `//${href}`}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={testId}
        data-gtm-id={`btn-${variant}`}
      >
        <ButtonContent icon={icon} iconPosition={iconPosition}>
          {children}
        </ButtonContent>
      </a>
    );
  }
  if (type === "div") {
    return (
      <div className={classes} data-testid={testId} data-gtm-id={`btn-${variant}`}>
        <ButtonContent icon={icon} iconPosition={iconPosition}>
          {children}
        </ButtonContent>
      </div>
    );
  }
  return null;
};

export default Button;

const sizeStyles = {
  normal: "py-4 px-8 text-button-sm tablet:text-button tablet:py-7 rounded-2xl before:rounded-2xl",
  small: "py-4 px-6 text-button-sm rounded-xl before:rounded-xl",
};

const variantStyles: Record<ButtonVariant, string> = {
  primary: `
    text-primary-black bg-primary-mint from-white to-primary-mint shadow-[rgba(1,135,134,0.4)] before:p-[2px] outline-white
    focus:shadow-none focus:bg-primary-black focus:text-white focus:from-primary-mint focus:to-primary-mint
    active:shadow-none active:bg-primary-mint active:shadow-none active:text-primary-black
    `,
  secondary: `
    text-primary-black bg-white border-2 border-primary-black shadow-[rgba(144,64,85,0.82)] outline-primary-mint
    focus:shadow-none
    active:shadow-none active:border-secondary-burgundy active:text-secondary-burgundy
  `,
  tertiary: `
    text-white bg-primary-black from-secondary-burgundy to-transparent shadow[rgba(144,64,85,0.82)] before:p-[2px] outline-white
    focus:shadow-none focus:from-secondary-grapefruit focus:to-secondary-grapefruit
    active:shadow-none active:from-secondary-burgundy active:to-secondary-burgundy
  `,
};

const variantHoverStyles: Record<ButtonVariant, string> = {
  primary: `
    hover:bg-primary-black hover:text-white hover:from-primary-mint hover:to-transparent
    `,
  secondary: `
    hover:border-secondary-burgundy hover:text-secondary-burgundy
  `,
  tertiary: `
    hover:shadow[rgba(144,64,85,0.82)] hover:from-secondary-burgundy hover:to-secondary-burgundy
  `,
};
