import { MouseEventHandler } from "react";
import Link from "next/link";

interface Props {
  href: string;
  title: string;
  onMouseEnter: MouseEventHandler<HTMLDivElement> | undefined;
  isHovering: boolean;
}

const NavbarMainLink: React.FC<Props> = ({ href, title, onMouseEnter, isHovering }: Props) => (
  <div className="flex select-none items-center px-6" onMouseEnter={onMouseEnter}>
    <Link href={href}>
      <a
        className={`text-menu-sm tablet:text-menu cursor-pointer py-4 font-bold ${
          isHovering
            ? "before:from-secondary-aubergine relative before:absolute before:bottom-0 before:h-[2px] before:w-full before:bg-gradient-to-r before:to-[rgba(0,0,0,0)] before:content-['']"
            : ""
        }`}
        data-gtm-id="main_navigation"
      >
        {title}
      </a>
    </Link>
  </div>
);

export default NavbarMainLink;
