import Image from "next/image";
import { ChangeEvent } from "react";
import { parseText } from "@/utilities/htmlParser";
import { emailIsValid } from "@/utilities/emailIsValid";
import { WhitepaperDialogProps } from "@/services/prepr/queries/getLayoutContent";
import { WhitepaperForm } from "@/services/salesforce/models/WhitepaperForm";
import { FORM_SOURCE_TYPE } from "@/services/salesforce/models/FormSource";
import { LEAD_SOURCE } from "@/services/salesforce/models/LeadSource";
import Button from "../Button";
import FormInput from "../Forms/FormInput";
import Checkbox from "../Checkbox/Checkbox";
import { useFormErrorDataLayerPush } from "../GTM/useFormErrorDataLayerPush";
import { useFormState } from "./useFormState";
import Dialog from "./Dialog";

const initialModel: WhitepaperForm = {
  firstName: "",
  lastName: "",
  email: "",
  companyName: "",
  subscribeToNewsletter: "false",
  formSource: FORM_SOURCE_TYPE.Whitepaper,
  leadSource: LEAD_SOURCE.webform,
  whitepaperTitle: "",
};

const initialValidation = {
  firstName: null,
  lastName: null,
  email: null,
  companyName: null,
  subscribeToNewsletter: null,
  formSource: null,
  leadSource: null,
  whitepaperTitle: null,
};

const getValidationMessage =
  (model: WhitepaperForm) =>
  (field: keyof WhitepaperForm): string | null => {
    const value = model[field] as string;
    if (field === "firstName" && !value) return "Voornaam is vereist";
    if (field === "lastName" && !value) return "Achternaam is vereist";
    if (field === "email") {
      if (!value) return "E-mail is vereist";
      if (!emailIsValid(value)) return "E-mail is ongeldig";
    }
    return null;
  };

type DialogProps = {
  slug: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSubmitted: () => void;
};

const DialogWhitepaper: React.FC<WhitepaperDialogProps & DialogProps> = ({
  title,
  subtitle,
  buttontext,
  image,
  subscribetext,
  endtext,
  isOpen,
  onClose,
  onSubmitted,
  body,
}) => {
  initialModel.whitepaperTitle = title ?? "";
  const { isError, handler, model, validation, handleClose, isLoading, validateAndSubmit } =
    useFormState({
      initialModel,
      initialValidation,
      onSubmitted,
      onClose,
      getValidationMessage,
      entity: "lead",
    });

  useFormErrorDataLayerPush("Whitepaper aanvraag", validation);
  const desktopImage = image?.[0]?.desktop ?? "";
  const handleNewsLetterCheckChange: (event: ChangeEvent<HTMLInputElement>) => void = (event) =>
    handler.onFieldChange("subscribeToNewsletter")(event.target.checked ? "true" : "false");
  return (
    <Dialog isOpen={isOpen} onClose={handleClose}>
      <div className="text-primary-black tablet:p-12 p-6 pb-8">
        <div className="tablet:grid-cols-4 mb-4 mr-8 grid overflow-hidden">
          <div className="col-start-1 col-end-4 flex flex-col justify-end">
            <p className="text-p-sm text-secondary-burgundy tablet:text-p mb-1 font-serif tracking-wider">
              {subtitle}
            </p>
            <h2 className="text-h3-sm tablet:text-h2-sm mb-2 font-sans font-bold tracking-wider">
              {title}
            </h2>
            {body && (
              <div className="text-p-sm tablet:mt-2 tablet:text-p mt-2 font-serif tracking-wider">
                {parseText(body)}
              </div>
            )}
          </div>
          {desktopImage && (
            <div className="tablet:block relative col-start-4 hidden h-48">
              <div className="bg-grey-light absolute bottom-0 aspect-square h-3/4 translate-x-5 translate-y-0.5 rounded-full"></div>
              <Image
                className="absolute bottom-0 rounded-b-full"
                src={image?.[0]?.desktop ?? ""}
                layout="fill"
                objectFit="contain"
                alt={image?.[0]?.description ?? "whitepaper dialog image"}
              ></Image>
            </div>
          )}
        </div>
        <div className="bg-grey-light tablet:py-10 tablet:px-12 tablet:pb-4 rounded-lg p-4 pb-6">
          <div>
            <div className="tablet:grid-cols-2 tablet:gap-6 mb-6 grid grid-cols-1 gap-4">
              <FormInput
                label="Voornaam"
                value={model.firstName}
                onChange={handler.onFieldChange("firstName")}
                onBlur={handler.onBlur("firstName")}
                error={validation["firstName"]}
                disabled={isLoading}
              />
              <FormInput
                label="Achternaam"
                value={model.lastName}
                onChange={handler.onFieldChange("lastName")}
                onBlur={handler.onBlur("lastName")}
                error={validation["lastName"]}
                disabled={isLoading}
              />
              <FormInput
                label="Bedrijfsnaam"
                value={model.companyName}
                onChange={handler.onFieldChange("companyName")}
                onBlur={handler.onBlur("companyName")}
                error={validation["companyName"]}
                disabled={isLoading}
              />
              <FormInput
                label="Zakelijke e-mailadres"
                value={model.email}
                onChange={handler.onFieldChange("email")}
                onBlur={handler.onBlur("email")}
                error={validation["email"]}
                disabled={isLoading}
              />
            </div>
            <div>
              <Checkbox
                name="subscribeToNewsletter"
                value=""
                className="text-p-sm mb-6 font-serif tracking-wider"
                testId="subscribeToNewsletter"
                label={subscribetext || ""}
                onChange={handleNewsLetterCheckChange}
                checked={model.subscribeToNewsletter === "true"}
                disabled={isLoading}
              />
              <Button
                variant="tertiary"
                onClick={validateAndSubmit}
                className="tablet:w-auto mb-5 w-full"
                size="small"
                aria-label="submit"
                disabled={isLoading}
              >
                {buttontext ?? ""}
              </Button>
              <span className="text-p-sm pl-4 font-serif tracking-wider">{endtext}</span>
            </div>
          </div>
          {isError && (
            <div className="text-p text-secondary-aubergine mt-6 w-full text-center font-serif font-bold tracking-wider">
              Er ging iets mis! Probeer later nog eens. Excuses voor het ongemak.
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default DialogWhitepaper;
