import Link from "next/link";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { updateConsent } from "@/components/shared/GTM/GTM";
import Sliders from "@/components/Icons/Sliders";
import Button from "../../shared/Button";
import Dialog from "../../shared/DialogForms/Dialog";
import Checkbox from "../../shared/Checkbox/Checkbox";

export interface CookieSettings {
  statistics_consent: boolean;
  marketing_consent: boolean;
}

interface CookieNotificationProps {
  editMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const CookieNotification: React.FC<CookieNotificationProps> = ({
  editMode = false,
  setEditMode,
}) => {
  const [cookieAccepted, setCookieAccepted] = useState<string | null>("true");
  const [statisticsConsent, setStatisticsConsent] = useState<boolean>(false);
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false);
  const router = useRouter();
  const [cookieOptionsVisible, setCookieOptionsVisible] = useState(false);

  useEffect(() => {
    if (!localStorage) {
      return setCookieAccepted(null);
    }
    const settings = localStorage.getItem("cookieConsentSettings");
    setCookieAccepted(settings);
    if (settings) {
      const parsedSettings: CookieSettings = JSON.parse(settings);
      setStatisticsConsent(parsedSettings.statistics_consent);
      setMarketingConsent(parsedSettings.marketing_consent);
    }
  }, []);

  const acceptCookieNotification = (settings: CookieSettings) => {
    if (localStorage) {
      localStorage.setItem("cookieConsentSettings", JSON.stringify(settings));
    }
    setCookieAccepted("true");

    updateConsent(settings);

    // TODO: check if we can fix this in tagmanager and remove the refresh
    if (editMode) {
      router.reload();
    }
  };

  const acceptAllCookies = () => {
    acceptCookieNotification({
      statistics_consent: true,
      marketing_consent: true,
    });
    setEditMode && setEditMode((prev) => !prev);
  };
  const renderDialog =
    router.asPath !== "/privacy-en-cookies" ? editMode || !cookieAccepted : editMode;
  return (
    <>
      {renderDialog ? (
        <Dialog
          className="tablet:px-10 px-6 pb-10 pt-14"
          isOpen={true}
          onClose={() => null}
          showCloseButton={false}
        >
          <h3 className="text-h3-sm tablet:text-h2-sm mb-11 font-bold tracking-wider">
            Hiltermann en cookies
          </h3>
          <div className="text-p max-h-[30vh] overflow-scroll font-serif tracking-wider">
            <p>
              Hiltermann Lease Groep B.V. verzamelt met cookies, of vergelijkbare technieken,
              informatie over jouw gebruik op de website of in de app. Niet alle informatie die
              wordt verzameld zijn persoonsgegevens, voor zover dat wel het geval is kun je in de
              privacyverklaring lezen hoe en waarom we deze persoonsgegevens verwerken. Voor
              technische informatie over de cookies kun je dat terugvinden in de cookieverklaring,
              daar beschrijven we ook in meer detail waarom we de cookies plaatsen. We plaatsen de
              volgende soorten cookies:
            </p>
            <br />
            <ol className="list-inside list-decimal">
              <li>
                Functionele cookies: deze cookies hebben geen gevolgen voor de privacy. Daarom is
                voor deze cookies geen toestemming nodig. We plaatsen deze cookies omdat ze
                noodzakelijk om de website goed te laten functioneren. Ook kun je hierbij denken aan
                cookies die inloggegeven bewaren of je taalinstellingen.
              </li>
              <br />
              <li>
                Analytische cookies: we plaatsen cookies om de website te verbeteren en te
                optimaliseren. Doorgaans hebben deze cookies geen tot zeer weinige gevolgen voor de
                privacy en behoeven daarom ook geen toesteming.
              </li>
              <br />
              <li>
                Tracking cookies en andere privacygevoelige technieken (zoals web beacons en
                javascripts die toegang geven tot informatie op de computer van de gebruiker): om je
                meer relevante informatie te tonen plaatsen we marketing cookies. Dit betekent dat
                je surfgedrag door middel van een tracking cookie wordt opgeslagen zodat er meer
                informatie bekend is en daarmee meer relevante content geplaatst kan worden. Meer
                informatie over deze cookies is terug te vinden in de cookieverklaring.
              </li>
            </ol>
            <br />

            <p>
              Stel je eigen keuze in. Je keuze geldt voor onze hele website en kun je altijd opnieuw
              aanpassen door onderaan op de pagina op ‘Cookie-instellingen’ te klikken. Lees meer in
              onze{" "}
              <Link href="/cookieverklaring">
                <a className="underline">cookieverkaring</a>
              </Link>{" "}
              en{" "}
              <Link href="/privacyverklaring">
                <a className="underline">privacyverklaring</a>
              </Link>
            </p>
          </div>
          {cookieOptionsVisible ? (
            <>
              <hr className="mt-9" />
              <div className="my-10">
                <Checkbox
                  name={"necessaryConsent"}
                  disabled={true}
                  label="Functionele cookies"
                  value={""}
                  checked={true}
                  onChange={() => undefined}
                />
                <Checkbox
                  name={"statisticsConsent"}
                  label="Analytische cookies"
                  value={""}
                  checked={statisticsConsent}
                  onChange={() => setStatisticsConsent((previous) => !previous)}
                />
                <Checkbox
                  name={"marketingConsent"}
                  label="Marketing cookies"
                  value={""}
                  checked={marketingConsent}
                  onChange={() => setMarketingConsent((previous) => !previous)}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <hr />
          {cookieOptionsVisible ? (
            <>
              <Button
                variant="tertiary"
                onClick={acceptAllCookies}
                className="tablet:w-auto mb-4 mr-4 mt-9 w-full"
              >
                Alle cookies accepteren
              </Button>
              <Button
                variant="secondary"
                className="tablet:w-auto w-full"
                onClick={() => {
                  acceptCookieNotification({
                    statistics_consent: statisticsConsent,
                    marketing_consent: marketingConsent,
                  });
                  setEditMode && setEditMode((prev) => !prev);
                }}
              >
                Keuze opslaan
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="tertiary"
                onClick={acceptAllCookies}
                className="tablet:w-auto mb-4 mr-4 mt-9 w-full"
              >
                Ja, ik ga akkoord met cookies
              </Button>
              <Button
                variant="secondary"
                onClick={() => setCookieOptionsVisible((prev) => !prev)}
                icon={<Sliders />}
                className="tablet:w-auto w-full"
              >
                Instellen
              </Button>
            </>
          )}
        </Dialog>
      ) : null}
    </>
  );
};
